import React from "react"
import Layout from '../../components/layout'
import H2 from '../../components/typography/h2'
// import SmallHero from '../components/small-hero'
import styled from "styled-components"
import { Helmet } from "react-helmet"
// import Hero from '../components/hero-small'
import Img from "gatsby-image/withIEPolyfill"
import { graphql } from 'gatsby'

const Services = ({ data }) => {

    return (
        <React.Fragment>
            <Layout>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Youth Camp | Collins Sporthorses</title>
                <meta name="description" content="Check out the services that we offer at Collins Sporthorses. From training to amazing facilities. We can help your horse be the best it can be." />
            </Helmet>

            <SlideImage>
            <Img
                fluid={data.hero.childImageSharp.fluid}
                objectFit='cover'
                objectPosition='50% 10%'
                alt='horses'
                style={{ width: "100%", height: "100%" }}
            />
            </SlideImage>
            <Wrapper>
                <Content>
                    <H2>2022 Youth Camp</H2>
                </Content>
            </Wrapper>

                <Container>
                <p>Come join us June 14th - 17th from 9am - 2pm with your horse crazy kids! With daily riding lessons, horse crafts each day, horse care and education, games and more! We are excited to welcome you to Collins SportHorses Camp!</p>
                <ul>
                    <li>$350 total</li>
                    <li>Ages 5+</li>
                    <li>Please bring water bottle</li>
                    <li>Daily snacks & water provided</li>
                    <li>Must have helmet & boots with 2 inch heel</li>
                    <li>Friday the 17th is pizza day!</li>
                </ul>
                <p>Call or text 260.437.7946 for more info & to reserve your spot!</p>
                <p>$150 non-refundable deposit required.</p>
                <p><a href="https://eform.pandadoc.com/?eform=3bd301d8-edbd-4fae-9f0a-9b7a94580df7">Click here to register and pay your deposit on line</a></p>
                </Container>

            </Layout>

        </React.Fragment>
    )
}

export default Services

const Container = styled.div`
    width: 90%;
    max-width: 960px;
    margin: 3em auto;
}
`

const SlideImage = styled.div`
position: absolute;
width: 100%;
z-index: 0;
height: 300px;
@media (min-width: 800px) {    
    height: 300px;
}
`;

const Content = styled.div`
max-width: 300px;
position: absolute;
text-align: left;
top: 15%;
text-shadow: 2px 0px 10px rgba(0,0,0,0.74);
h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3em;
}
p {
  font-size: 1.5em;
  font-weight: 700; 
}
@media (min-width: 800px) {
    max-width: 475px;
    h2 {
        font-size: 4em;
        margin-bottom: 25px;
    }
}
`;

const Wrapper = styled.div`
color: #fff;
height: 300px;
margin: auto;
max-width: 85%;
position: relative;
width: 85%;
z-index: 1;
a {
    text-decoration: none;
}
@media (min-width: 800px) {
    height: 300px;
    max-width: 1600px;
    width: 85%;
}
`;



export const query = graphql`
query {
  hero: file(relativePath: { eq: "images/services.JPG" }) {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

